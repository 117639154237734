import { fetchRefreshToken } from "@/modules/auth/service"
import store from "@/store"
import VueCookies from "vue-cookies"
// import router from "../routes";



// for multiple requests
let isRefreshing = false;
let failedQueue = [];


const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};


const refreshTokenInterceptor = (axiosInstance) => async (error) => {
    const _axios = axiosInstance;
    const originalRequest = error.config;
    const { status } = error.response

    if (!originalRequest._retry) {
        if (( status === 401)) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers["Authorization"] = "Bearer " + token;
                        return _axios.request(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }
    
            originalRequest._retry = true;
            isRefreshing = true;
    
            const access_token = VueCookies.get("token");
            const refresh_token = VueCookies.get("refresh_token");
            return new Promise((resolve, reject) => {
                fetchRefreshToken(access_token, refresh_token)
                    .then((token) => {
                        VueCookies.set("token", token, {path: "/"});
                        _axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                        originalRequest.headers["Authorization"] = "Bearer " + token;
                        processQueue(null, token);
                        resolve(_axios(originalRequest));
                    })
                    .catch((err) => {
                        processQueue(err, null);
                        failedQueue = [];
                        reject(err);
                        store.dispatch("logoutUser");
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            });
        } else if (( status === 403)) {
            try {
                // router.push({name: 'unauthenticated'})
                console.log('object :>> ', status);
            } catch (error) {
                console.log('error :>> ', error);
            }
        }
    }
    
    return Promise.reject(error);
};

export default refreshTokenInterceptor;