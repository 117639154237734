import axios from "@/axios";
import { cloneDeep, isEqual } from "lodash";
import { getPackagesCheck } from "@/modules/checks/services.js";

export const PackageStore = {

    state: {
        packageData: null,
        originPackageData: null,
        packageSections: null,
        packageChecks: null,
        packageForms: null,
        roles: [],
        isDirty: false
    },

    mutations: {
        SET_PACKAGE(state, packageData) {
            state.packageData = cloneDeep(packageData);
            state.originPackageData = cloneDeep(packageData);
            state.isDirty = false;
        },
        SET_PACKAGE_SECTIONS(state, payload) {
            state.packageSections = payload;
        },
        SET_PACKAGE_CHECKS(state, payload) {
            state.packageChecks = payload;
        },
        SET_PACKAGE_FORMS(state, payload) {
            state.packageForms = payload;
        },
        RESET_PACKAGE(state) {
            state.packageData = null;
            state.packageSections = null;
            state.packageChecks = null;
            state.packageForms = null;
        },
        SET_ROLES(state, rolesData) {
            state.roles = rolesData
        },
        SET_DIRTY(state, payload) {
            // eslint-disable-next-line no-unused-vars
            let { entity_types, ...restPayload } = payload;
            // eslint-disable-next-line no-unused-vars
            const { entity_types: origin_entity_types, ...restoriginPackageData } = state.originPackageData;

            const collectionNames = [
                'entity_types'
            ];

            const entity_typesModel = {
                id: 'string',
                maximum_entity_count: 'number',
                minimum_entity_count: 'number',
                name: 'string'
            }
            
            const isCollectionsEqual = collectionNames.every(collectionName => {
                let isEqual = true;

                const source = state.originPackageData[collectionName];
                const target = payload[collectionName]

                if (source.length != target.length) {
                    isEqual = false;
                } else {
                    for (let i = 0; i < source.length; i++) {
                        const sourceItem = source[i]
                        const targetItem = target.find(x => x.id == sourceItem.id)
                        if (!targetItem) {
                            isEqual = false;
                            break;
                        } else {
                            for (const key in entity_typesModel) {
                                if (sourceItem[key] != targetItem[key]) {
                                    isEqual = false;
                                    break;
                                }
                            }
                        }
                    }
                }
                return isEqual;
            })
            
            state.isDirty = !isCollectionsEqual || !isEqual(cloneDeep(restPayload), cloneDeep(restoriginPackageData))
        },
    },

    getters: {
        getPackage: state => state.packageData,
        getPackageSections: state => state.packageSections,
        getPackageChecks: state => state.packageChecks,
        getPackageForms: state => state.packageForms,
        getRoles: state => state.roles,
    },

    actions: {
        fetchPackageData: async ({ commit }, payload) => {
            if (payload) {
                const package_id = payload
                const url = `/screening-package/${package_id}`;

                try {
                    const { data } = await axios.get(url);
                    commit('SET_PACKAGE', data);
                    return data;
                } catch (error) {
                    console.log(error, ">>>error fetch package data");
                }
            }
        },
        fetchPackageSections: async ({ commit }, package_id) => {
            if (package_id) {
                const url = `/package-section/${package_id}/section`;
                try {
                    const { data } = await axios.get(url);
                    commit('SET_PACKAGE_SECTIONS', data.data);
                    return data.data;
                } catch (error) {
                    console.log(error, ">>>error fetch package sections data");
                }
            }
        },
        fetchPackageEntitySections: async ({ commit }, payload) => {
            if (!payload) return;

            const { package_id, entity_type_id } = payload;
            const url = `/package-section/${package_id}/entity/${entity_type_id}/section`;
    
            try {
                const { data } = await axios.get(url);
                commit('SET_PACKAGE_SECTIONS', data.data);
                return data.data;
            } catch (error) {
                    console.log(error, ">>>error fetch package sections data");
            }
        },
        fetchPackageChecks: async ({ commit }, package_id) => {
            if (package_id) {
                try {
                    const { checks, forms } = await getPackagesCheck(package_id);
                    commit('SET_PACKAGE_CHECKS', checks);
                    commit('SET_PACKAGE_FORMS', forms);
                    return { checks, forms };
                } catch (error) {
                    console.log(error, ">>>error fetch package checks data");
                }
            }
        },
        fetchRoles: async ({ commit }) => {
            const url = `/tenant-check/section/field/valid-roles/`;
            try {
                const { data } = await axios.get(url);
                commit('SET_ROLES', data.roles);
            } catch (error) {
                console.log(error, '>>>fetch roles data');
            }
        },
        handlePackageDataChange: ({ commit }, payload) => {
             commit('SET_DIRTY', payload);
        },

    }

};
