import axios from "@/axios";
export const DashboardStore = {
    state: {
        notificationCount: 0,
        caseNotificationCount: 0,
    },

    mutations: {
        SET_NOTIFICATION_COUNT(state, count) {
            state.notificationCount = count;
        },
        SET_CASE_NOTIFICATION_COUNT(state, count) {
            state.caseNotificationCount = count;
        },
    },

    getters: {
        getNotificationCount: (state) => state.notificationCount,
        getCaseNotificationCount: (state) => state.caseNotificationCount,
    },

    actions: {
        fetchNotificationCount: async ({ commit }) => {
            try {
                const { data } = await axios.get(`dashboard-notifications/tenant/count`);
                commit("SET_NOTIFICATION_COUNT", data.data);
            } catch (error) {
                console.log(">>>> error", error);
            }
        },
        fetchCaseNotificationCount: async ({ commit }, payload) => {
            let case_id = payload
            if (case_id) {
                try {
                    let url = `activity-history/notification/count/${case_id}`
                    const {data} = await axios.get(url);
                    commit("SET_CASE_NOTIFICATION_COUNT", data.count || 0);
                } catch (error) {
                    console.log(">>>> error", error);
                }
                
            }
        },
    },
};
