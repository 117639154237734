export let Store = {
    state: {
        loginTenantDetails: null,
    },
    mutations: {
        SET_TENANT_DETAILS(state, payload) {
            state.loginTenantDetails = payload;
            document.title = payload.domain_name;
        },
    },
    getters: {
        getLoginTenantDetails: (state) => state.loginTenantDetails,
    },
    actions: {
        setTenantDetails: ({ commit }, payload) => {
            commit("SET_TENANT_DETAILS", payload);
        },
    },
};