import { render, staticRenderFns } from "./ContentBox.vue?vue&type=template&id=7370fe90&scoped=true"
import script from "./ContentBox.vue?vue&type=script&lang=js"
export * from "./ContentBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7370fe90",
  null
  
)

export default component.exports