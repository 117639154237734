<template>
  <div class="flex gap-x-2" v-if="model">
    <DatePicker
      value-type="YYYY-MM-DD HH:mm:ss"
      :value="getValue.from"
      @change="onChange($event, 'from')"
      :key="context.id + 'from' + context.from"
      :clearable="false"
      format="DD-MMM-YYYY"
      :disabled="isDisabled"
    />
    <DatePicker
      class="rounded border-0 w-64"
      :key="context.id + 'to' + context.from"
      :value="getValue.to"
      @change="onChange($event, 'to')"
      value-type="YYYY-MM-DD HH:mm:ss"
      :clearable="false"
      format="DD-MMM-YYYY"
      :disabled="isDisabled"
    />
   
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { isJson } from "../../components/form-builder/utils";
export default {
  props: {
    // value: {
    //   required: true,
    // },
    context: {
      type: Object,
      required: true,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      to: null,
      from: null,
    };
  },

  computed: {
    model() {
      let model = null;
      try {
        if (this.context.model) {
          model = isJson(this.context.model) ? JSON.parse(this.context.model) : this.context.model;
        } else {
          model =  { to: new Date(), from: new Date() };
        }
        // console.log("model :>> ", model);
      } catch (error) {
        console.log("error :>> ", error);
      }
      return model;
    },
    getValue: {
      get() {
        let model = this.model;
        if (model && model.to && model.from) {
          return {
            to: model.to,
            from: model.from,
          };
        } else {
          return {
            to: new Date(),
            from: new Date(),
          };
        }
      },
      set(val) {
        // this.$emit("change",);
        console.log("val :>> ", val);
      },
    },
    isDisabled() {
      return this.context?.attributes?.disabled ?? false
    }
  },
  methods: {
    onChange(val, type) {
      // console.log('val :>> ', val, type);
      let date = this.getValue;
      date[type] = val;
      this.context.model = JSON.stringify(date);
      // console.log('this.con :>> ', this.context.model);
    },
    setValue() {},
  },
  mounted() {
    // console.log('this.context.model :>> ', this.context);
    // console.log('this.context :>> ',  [this.model, this.context.model.from] );
  },
};
</script>

<style>
</style>