import { uniq } from "lodash";
import { getJurisdictionName } from "../../../utils";

export const  nodeKeyTypeMap = {
    company: "Company Name",
    current_employer: "Current Employer",
    person: "Name",
    officerName: "Name",
    name: "Name",
    full_name: "Name",
    nickname: "name",
    registrant_name: "name",
    registrant: "name",
    user: "name",
    bio: "github bio",
    gid: "Google id",
    id: "Github id",
    number: "phone",
    registrant_phone: "phone",
    registrant_email: "email",
    company_name: "Company Name",
    companyName: "Company Name",
    birthday: "Date of birth",
    education_institute: "education",
    link: "url",
    current_title: "Employment title",
    registrant_address: "location",
    registrant_country: "location",
    registrant_city: "location",
    registrant_state: "location",
    city: "location",
    country: "location",
    address: "location",
    province: "location",
    query: "Search engine query",
    twitter_username: "username",
};
function getAnalyserSource(key, matchKeyword, matchSource) {
    if (key && typeof key === "string" && key?.toLowerCase() === matchKeyword) return matchSource || "name";
    else return matchSource ? "name" : "company_name";
}
function handleBVDgraph(node, to_id) {
    let graphNodes = [];
    let graphLinks = [];
    Array.isArray(node?.PHONE_NUMBER ?? []) &&
        node?.PHONE_NUMBER?.forEach((phone) => {
            const id = `phone_number_${phone}`;
            const officers_elem = {
                id: id,
                label: phone,
                source: ["company"],
                searchType: "phone",
                type: "phone",
            };
            graphNodes.push(officers_elem);
            graphLinks.push({
                from: id,
                to: to_id,
                title: "CompanyName",
            });
        });
    Array.isArray(node?.SUBSIDIARIES_INFO ?? []) &&
        node?.SUBSIDIARIES_INFO?.forEach((ele3) => {
            if (ele3.SUB_NAME) {
                const id = `company__name___${ele3.SUB_NAME}__`;
                const officers_elem = {
                    id: id,
                    label: ele3.SUB_NAME,
                    source: ["company"],
                    searchType: "name",
                    type: getAnalyserSource(ele3.SUB_ENTITY_TYPE, "one or more named individuals or families"),
                };
                graphNodes.push(officers_elem);
                graphLinks.push({
                    from: id,
                    to: to_id,
                    title: "CompanyName",
                });
                if (ele3.SUB_PHONE_NUMBER) {
                    const sub_phone_id = `sub__phone__${ele3.SUB_PHONE_NUMBER}__`;
                    const phone_elem = {
                        id: sub_phone_id,
                        label: ele3.SUB_PHONE_NUMBER,
                        source: ["company"],
                        searchType: "phone",
                        type: "phone",
                    };
                    graphNodes.push(phone_elem);
                    graphLinks.push({
                        from: sub_phone_id,
                        to: id,
                        title: "CompanyName",
                    });
                }
            }
        });

    Array.isArray(node?.SHAREHOLDER_INFORMATION ?? []) &&
        node?.SHAREHOLDER_INFORMATION?.forEach((shareHolder) => {
            if (shareHolder.SH_NAME) {
                const id = `company__name___${shareHolder.SH_NAME}__share_holder`;
                const officers_elem = {
                    id: id,
                    label: shareHolder.SH_NAME,
                    source: ["company"],
                    searchType: "name",
                    type: getAnalyserSource(shareHolder.SH_ENTITY_TYPE, "one or more named individuals or families"),
                };
                graphNodes.push(officers_elem);
                graphLinks.push({
                    from: id,
                    to: to_id,
                    title: "CompanyName",
                });
                // type: shareHolder.SH_ENTITY_TYPE === "Financial company" ? "company" : "name",
                if (shareHolder?.SUB_PHONE_NUMBER) {
                    const sub_phone_id = `sub__phone__${shareHolder.SUB_PHONE_NUMBER}__`;
                    const phone_elem = {
                        id: sub_phone_id,
                        label: shareHolder.SUB_PHONE_NUMBER,
                        source: ["company"],
                        searchType: "phone",
                        type: "phone",
                    };
                    graphNodes.push(phone_elem);
                    graphLinks.push({
                        from: sub_phone_id,
                        to: id,
                        title: "CompanyName",
                    });
                }
            }
        });

    Array.isArray(node?.SHAREHOLDERS ?? []) &&
        node?.SHAREHOLDERS?.forEach((shareHolder) => {
            if (shareHolder.SH_LAST_NAME || shareHolder.SH_FIRST_NAME) {
                const id = `company__name___${shareHolder.SH_FIRST_NAME}__${shareHolder.SH_LAST_NAME}__share_holder`;
                const officers_elem = {
                    id: id,
                    label: `${shareHolder.SH_FIRST_NAME} ${shareHolder.SH_LAST_NAME}`,
                    source: ["company"],
                    searchType: "name",
                    type: getAnalyserSource(shareHolder.SH_ENTITY_TYPE, "one or more named individuals or families"),
                };
                graphNodes.push(officers_elem);
                graphLinks.push({
                    from: id,
                    to: to_id,
                    title: "CompanyName",
                });
                // type: shareHolder.SH_ENTITY_TYPE === "Financial company" ? "company" : "name",
            }
        });

    Array.isArray(node?.GLOBAL_ULTIMATE_OWNER_INFO ?? []) &&
        node?.GLOBAL_ULTIMATE_OWNER_INFO?.forEach((owners) => {
            if (owners.GUO_NAME) {
                const id = `company__name___${owners.GUO_NAME}__owner`;
                const officers_elem = {
                    id: id,
                    label: owners.GUO_NAME,
                    source: ["company"],
                    searchType: "name",
                    type: getAnalyserSource(owners.GUO_ENTITY_TYPE, "one or more named individuals or families"),
                };
                graphNodes.push(officers_elem);
                graphLinks.push({
                    from: id,
                    to: to_id,
                    title: "CompanyName",
                });
                // type: shareHolder.SH_ENTITY_TYPE === "Financial company" ? "company" : "name",
                if (owners?.GUO_PHONE_NUMBER) {
                    const sub_phone_id = `sub__phone__${owners.GUO_PHONE_NUMBER}__`;
                    const phone_elem = {
                        id: sub_phone_id,
                        label: owners.GUO_PHONE_NUMBER,
                        source: ["company"],
                        searchType: "phone",
                        type: "phone",
                    };
                    graphNodes.push(phone_elem);
                    graphLinks.push({
                        from: sub_phone_id,
                        to: id,
                        title: "CompanyName",
                    });
                }
            }
        });

    Array.isArray(node?.CONTROLLING_SHAREHOLDERS ?? []) &&
        node?.CONTROLLING_SHAREHOLDERS?.forEach((shareholder) => {
            if (shareholder.CSH_NAME) {
                const id = `company__name___${shareholder.CSH_NAME}__shareholder`;
                const officers_elem = {
                    id: id,
                    label: shareholder.CSH_NAME,
                    source: ["company"],
                    searchType: "name",
                    type: getAnalyserSource(shareholder.CSH_ENTITY_TYPE, "one or more named individuals or families"),
                };
                graphNodes.push(officers_elem);
                graphLinks.push({
                    from: id,
                    to: to_id,
                    title: "CompanyName",
                });
                if (shareholder?.CSH_PHONE_NUMBER) {
                    const sub_phone_id = `sub__phone__${shareholder.CSH_PHONE_NUMBER}__`;
                    const phone_elem = {
                        id: sub_phone_id,
                        label: shareholder.CSH_PHONE_NUMBER,
                        source: ["company"],
                        searchType: "phone",
                        type: "phone",
                    };
                    graphNodes.push(phone_elem);
                    graphLinks.push({
                        from: sub_phone_id,
                        to: id,
                        title: "CompanyName",
                    });
                }
            }
        });

    Array.isArray(node?.IMMEDIATE_PARENT_INFO ?? []) &&
        node?.IMMEDIATE_PARENT_INFO?.forEach((info) => {
            if (info.ISH_NAME) {
                const id = `company__name___${info.ISH_NAME}__info`;
                const officers_elem = {
                    id: id,
                    label: info.ISH_NAME,
                    source: ["company"],
                    searchType: "name",
                    type: getAnalyserSource(info.ISH_ENTITY_TYPE, "one or more named individuals or families"),
                };
                graphNodes.push(officers_elem);
                graphLinks.push({
                    from: id,
                    to: to_id,
                    title: "CompanyName",
                });
                if (info?.ISH_PHONE_NUMBER) {
                    const sub_phone_id = `sub__phone__${info.ISH_PHONE_NUMBER}__`;
                    const phone_elem = {
                        id: sub_phone_id,
                        label: info.ISH_PHONE_NUMBER,
                        source: ["company"],
                        searchType: "phone",
                        type: "phone",
                    };
                    graphNodes.push(phone_elem);
                    graphLinks.push({
                        from: sub_phone_id,
                        to: id,
                        title: "CompanyName",
                    });
                }
            }
        });

    Array.isArray(node?.DIRECTORS ?? []) &&
        node?.DIRECTORS?.forEach((director) => {
            if (director.CPYCONTACTS_HEADER_FullNameOriginalLanguagePreferred) {
                const id = `company__name___${director.CPYCONTACTS_HEADER_FullNameOriginalLanguagePreferred}__director`;
                const officers_elem = {
                    id: id,
                    label: director.CPYCONTACTS_HEADER_FullNameOriginalLanguagePreferred,
                    source: ["company"],
                    searchType: "name",
                    type: getAnalyserSource(director.CPYCONTACTS_MEMBERSHIP_Function, "auditor", "company_name"),
                };
                graphNodes.push(officers_elem);
                graphLinks.push({
                    from: id,
                    to: to_id,
                    title: "CompanyName",
                });
            }
        });
    return { nodes: graphNodes, links: graphLinks };
}
function createCenterNode(searchInput) {
    let isMultiLineCenterNode = false;
    console.log('', isMultiLineCenterNode)
    let count = 0;
    let emailIndex = -1;
    let nameIndex = -1;
    let priorityInputNode = null;
    console.log('', priorityInputNode)
    const inputs = Object.entries(searchInput);
    let inputType = null;
    for (let i = 0; i < inputs.length; i++) {
        const [k, v] = inputs[i];
        if (k === "email") emailIndex = i;
        if (k === "name" || k === "username") nameIndex = true;
        if (v !== "") {
            inputType = k;
            count++;
        }
    }
    if (count >= 2) {
        isMultiLineCenterNode = true;
        // If we have multiple inputs & email is present maek it as priority
        if (emailIndex >= 0) priorityInputNode = inputs[emailIndex][1];
        // else if we have name as input make it as priority
        else if (nameIndex >= 0) priorityInputNode = inputs[nameIndex][1];
        //else pick the first input as priority node.
        else priorityInputNode = inputs[0][1];
    }
    let name = "";
    // try {
    //     let checkName = searchInput["first_name"].length > 0 || searchInput["last_name"].length > 0 || searchInput["middle_name"];
    //     if (this.activeProcess === "person-identifier-tool" && !type && type !== "case-data" && checkName) {
    //         //Fix this(NOTE)
    //         // name = `${searchInput["first_name"]} ${searchInput["middle_name"]} ${searchInput["last_name"]}`;
    //         name = [searchInput["first_name"], searchInput["middle_name"], searchInput["last_name"]].filter((e) => e.trim() !== "").join(" ");
    //         delete searchInput["first_name"];
    //         delete searchInput["last_name"];
    //         delete searchInput["middle_name"];
    //     }
    // } catch (error) {
    //     if (searchInput.name) {
    //         name = searchInput.name;
    //     }
    // }
    let config = {
        shape: "box",
        label: uniq([name, ...Object.values(searchInput)])
            .filter((o) => o !== "" && o !== null && o !== "undefined" && o !== " ")
            .join(",\n"),
    };

    if (count === 1) {
        config["type"] = inputType;
    } else {
        config["type"] = "multi_query_center_node";
    }

    searchInput["name"] = `${name}`;
    return config
}

export const makeCompanyGraph = (data, uniqueKey) => {
    // debugger //eslint-disable-line no-debugger
    let nodes = data.data;
    let graphNodes = [];
    let graphLinks = [];
    // nodes = nodes.map((ele) => ele.company).filter((ele) => ele?.jurisdiction);
    nodes = nodes.filter((ele) => ele?.company?.jurisdiction_code);
    let dups = [];
    nodes.forEach((elem) => {
        let main_node_id = `company___${getJurisdictionName(elem.company?.jurisdiction_code)}`;

        let name_search_node = {
            id: main_node_id,
            source: ["company"],
            label: getJurisdictionName(elem.company?.jurisdiction_code.split("_")[0]),
            searchType: "name",
            type: "country",
            flag_code: elem.company?.jurisdiction_code.split("_")[0],
            flag: true,
        };

        graphNodes.push(name_search_node);
        graphLinks.push({ from: main_node_id, to: uniqueKey, title: "CompanyName" });

        const all_comp = nodes.filter((n) => n.company?.jurisdiction_code === elem.company?.jurisdiction_code);

        all_comp.forEach((ele) => {
            if (ele?.company?.name) {
                const id = `company__name__${ele.company?.name}`;
                const company_elem = {
                    id: id,
                    label: ele?.company?.name,
                    source: ["company"],
                    searchType: "company",
                    type: "company",
                };

                graphNodes.push(company_elem);
                graphLinks.push({
                    from: id,
                    to: main_node_id,
                    title: "CompanyName",
                });

                // Opencorporates
                if (ele?.api_all_data?.officer?.name || ele?.api_all_data?.officer?.directorName) {
                    const id = `officer__name__${ele.api_all_data.officer.name}` || `officer__name__${ele.api_all_data.officer.directorName}`;
                    const officers_elem = {
                        id: id,
                        label: ele.api_all_data.officer.name || ele.api_all_data.officer.directorName,
                        source: ["company"],
                        searchType: "name",
                        type: "name",
                    };

                    graphNodes.push(officers_elem);
                    graphLinks.push({
                        from: id,
                        to: company_elem.id,
                        title: "CompanyName",
                    });
                }

                ele?.company_details?.company?.officers?.forEach((ele1) => {
                    const id = `officer__name__${ele1.officer.name}`;
                    const officers_elem = {
                        id: id,
                        label: ele1.officer.name,
                        source: ["company"],
                        searchType: "name",
                        type: ele1.officer.entity_type === "Organisation" || ele1.officer.entity_type === "Company" ? "company" : "name",
                    };

                    graphNodes.push(officers_elem);
                    graphLinks.push({
                        from: id,
                        to: company_elem.id,
                        title: "CompanyName",
                    });
                });

                const { nodes, links } = handleBVDgraph(ele, company_elem.id);
                graphNodes.push(...nodes);
                graphLinks.push(...links);
                // ele?.api_all_data?.PHONE_NUMBER?.forEach((ele2) => {
                //     const id = `phone_number_${ele2}`;
                //     const officers_elem = {
                //         id: id,
                //         label: ele2,
                //         source: ["company"],
                //         searchType: "phone",
                //         type: "phone",
                //     };
                //     graphNodes.push(officers_elem);
                //     graphLinks.push({
                //         from: id,
                //         to: company_elem.id,
                //         title: "CompanyName",
                //     });
                // });

                // ele?.api_all_data?.SUBSIDIARIES_INFO?.forEach((ele3) => {
                //     if (ele3.SUB_NAME) {
                //         const id = `company__name___${ele3.SUB_NAME}__`;
                //         const officers_elem = {
                //             id: id,
                //             label: ele3.SUB_NAME,
                //             source: ["company"],
                //             searchType: "name",
                //             type: "company",
                //         };
                //         graphNodes.push(officers_elem);
                //         graphLinks.push({
                //             from: id,
                //             to: company_elem.id,
                //             title: "CompanyName",
                //         });
                //         if (ele3.SUB_PHONE_NUMBER) {
                //             const sub_phone_id = `sub__phone__${ele3.SUB_PHONE_NUMBER}__`;
                //             const phone_elem = {
                //                 id: sub_phone_id,
                //                 label: ele3.SUB_PHONE_NUMBER,
                //                 source: ["company"],
                //                 searchType: "phone",
                //                 type: "phone",
                //             };
                //             graphNodes.push(phone_elem);
                //             graphLinks.push({
                //                 from: sub_phone_id,
                //                 to: id,
                //                 title: "CompanyName",
                //             });
                //         }
                //     }
                // });

                ele?.chain_data?.entity?.forEach((ele2) => {
                    const id = `ubo__name__${ele2.name}`;
                    const ubo_elem = {
                        id: id,
                        label: ele2.name,
                        source: ["company"],
                        searchType: "name",
                        type: ele2.name_type == "Company" ? "company" : "name",
                    };

                    graphNodes.push(ubo_elem);
                    graphLinks.push({
                        from: id,
                        to: company_elem.id,
                        title: "CompanyName",
                    });
                });
            }
            //Clarified by
            if (ele?.api_all_data?.subject_name?.name_en) {
                const id = `company__name__${ele.api_all_data.subject_name.name_en}`;
                const company_elem = {
                    id: id,
                    label: ele.api_all_data.subject_name.name_en,
                    source: ["company"],
                    searchType: "company",
                    type: "name",
                };

                graphNodes.push(company_elem);
                graphLinks.push({
                    from: id,
                    to: main_node_id,
                    title: "CompanyName",
                });
                ele?.management?.results?.forEach((result) => {
                    if (result?.entity_name?.name_en) {
                        const result_id = `company_management__${result?.entity_name?.name_en}`;
                        const result_elem = {
                            id: result_id,
                            label: result?.entity_name?.name_en,
                            source: ["company"],
                            searchType: "name",
                            type: result.entity_type === "Organisation" || result.entity_type === "Company" ? "company" : "name",
                        };

                        graphNodes.push(result_elem);
                        graphLinks.push({
                            from: result_id,
                            to: company_elem.id,
                            title: "CompanyName",
                        });
                    }
                });
                ele?.holdings?.results?.forEach((result) => {
                    if (result?.entity_name?.name_en) {
                        const result_id = `company_management__${result?.entity_name?.name_en}`;
                        const result_elem = {
                            id: result_id,
                            label: result?.entity_name?.name_en,
                            source: ["company"],
                            searchType: "name",
                            type: result.entity_type === "Organisation" || result.entity_type === "Company" ? "company" : "name",
                        };

                        graphNodes.push(result_elem);
                        graphLinks.push({
                            from: result_id,
                            to: company_elem.id,
                            title: "CompanyName",
                        });
                    }
                });
                ele?.activities?.results?.forEach((result) => {
                    if (result?.entity_name?.name_en) {
                        const result_id = `company_management__${result?.entity_name?.name_en}`;
                        const result_elem = {
                            id: result_id,
                            label: result?.entity_name?.name_en,
                            source: ["company"],
                            searchType: "name",
                            type: result.entity_type === "Organisation" || result.entity_type === "Company" ? "company" : "name",
                        };

                        graphNodes.push(result_elem);
                        graphLinks.push({
                            from: result_id,
                            to: company_elem.id,
                            title: "CompanyName",
                        });
                    }
                });
                ele?.ownership?.results?.forEach((result) => {
                    if (result?.entity_name?.name_en) {
                        const result_id = `company_management__${result?.entity_name?.name_en}`;
                        const result_elem = {
                            id: result_id,
                            label: result?.entity_name?.name_en,
                            source: ["company"],
                            searchType: "name",
                            type: result.entity_type === "Organisation" || result.entity_type === "Company" ? "company" : "name",
                        };

                        graphNodes.push(result_elem);
                        graphLinks.push({
                            from: result_id,
                            to: company_elem.id,
                            title: "CompanyName",
                        });
                    }
                });
            }
        });
        //Company Watch Logic
        // const all_officers = nodes
        //     .map((ele) => ({
        //         ...ele.api_all_data,
        //         ...{ CurrentAppointments: ele.CurrentAppointments },
        //         ...{ PreviousAppointments: ele.PreviousAppointments },
        //     }))
        //     .filter((ele) => ele.directorName);

        // all_officers.forEach((ele) => {
        //     const id = `officer__name__${ele.directorName}`;
        //     const company_elem = {
        //         id: id,
        //         label: ele.directorName,
        //         source: ["company"],
        //         searchType: "name",
        //         type: "name",
        //     };

        //     graphNodes.push(company_elem);
        //     graphLinks.push({
        //         from: id,
        //         to: main_node_id,
        //         title: "CompanyName",
        //     });
        //     ele?.CurrentAppointments?.forEach((ele2) => {
        //         const id = `company__name__${ele2.companyName}__`;
        //         const ubo_elem = {
        //             id: id,
        //             label: ele2.companyName,
        //             source: ["company"],
        //             searchType: "name",
        //             type: "company",
        //         };

        //         graphNodes.push(ubo_elem);
        //         graphLinks.push({
        //             from: id,
        //             to: company_elem.id,
        //             title: "CompanyName",
        //         });
        //     });
        //     ele?.PreviousAppointments?.forEach((ele2) => {
        //         const id = `company__name__${ele2.companyName}__`;
        //         const ubo_elem = {
        //             id: id,
        //             label: ele2.companyName,
        //             source: ["company"],
        //             searchType: "name",
        //             type: "company",
        //         };

        //         graphNodes.push(ubo_elem);
        //         graphLinks.push({
        //             from: id,
        //             to: company_elem.id,
        //             title: "CompanyName",
        //         });
        //     });
        // });

        // const all_shareholders = nodes.map((ele) => ele.api_all_data).filter((ele) => ele.shareholders);
        // all_shareholders.forEach((ele) => {
        //     const id = `company__${ele.name}`;
        //     const company_elem = {
        //         id: id,
        //         label: ele.name,
        //         source: ["company"],
        //         searchType: "company",
        //         type: "company_name",
        //     };

        //     graphNodes.push(company_elem);
        //     graphLinks.push({
        //         from: id,
        //         to: main_node_id,
        //         title: "CompanyName",
        //     });
        //     ele.shareholders?.forEach((shareHolder) => {
        //         if (shareHolder.fullName) {
        //             const id = `company__name___${shareHolder.fullName}__share_holder`;
        //             const officers_elem = {
        //                 id: id,
        //                 label: `${shareHolder.fullName}`,
        //                 source: ["company"],
        //                 searchType: "name",
        //                 type: getAnalyserSource(shareHolder.shareholderType?.description, "individual"),
        //             };
        //             graphNodes.push(officers_elem);
        //             graphLinks.push({
        //                 from: id,
        //                 to: company_elem.id,
        //                 title: "CompanyName",
        //             });
        //             // type: shareHolder.SH_ENTITY_TYPE === "Financial company" ? "company" : "name",
        //         }
        //     });
        // });
    });

    //BVD officers
    let bvd_officers_node = data.data.filter((ele) => ele.key === "officer_bvd");
    if (bvd_officers_node.length > 0) {
        bvd_officers_node.forEach(({ api_all_data: node }) => {
            let main_node_id = `company___${node.CONTACTS_MEMBERSHIP_NameCompany}`;
            let officer_bvd_node = {
                id: main_node_id,
                source: ["company"],
                label: node.CONTACTS_MEMBERSHIP_NameCompany,
                searchType: "company",
                type: "company",
            };
            graphNodes.push(officer_bvd_node);
            graphLinks.push({ from: main_node_id, to: uniqueKey, title: "CompanyName" });
            if (node.officerName) {
                let id = `company___${node.officerName}__officer_bvd`;
                let officer_bvd_node = {
                    id: id,
                    source: ["company"],
                    label: node.officerName,
                    searchType: "name",
                    type: "name",
                };
                graphNodes.push(officer_bvd_node);
                graphLinks.push({ from: id, to: main_node_id, title: "CompanyName" });
                const { nodes, links } = handleBVDgraph(node, officer_bvd_node.id);
                graphNodes.push(...nodes);
                graphLinks.push(...links);
            }
        });
    }

    return { nodes: graphNodes, links: graphLinks, dups: dups };
};
export const onSearch = async(data,uniqueKey) => {
    // debugger //eslint-disable-line no-debugger
     const queryData= {};
    let input = data.data;
    let searchData = { ...input };
    let source = data.source;
    let searchType = data.searchType;
    // let type = data.type;

    let searchedNodes = [];
    let searchedLinks = [];
    let centerNodeConfig = createCenterNode(searchData);
    centerNodeConfig.label = `${centerNodeConfig.label}`;
    // let uniqueKey = `${this.activeProcess}__${Object.values(searchData).join("__")}`;

    let centerNode = {
        ...centerNodeConfig,
        margin: 20,
        color: { border: "#4220FB", font: "#fff", background: "#6E6EFD" },
        borderWidth: 2,
        id: uniqueKey,
        source: [source],
        isQueryNode: true,
    };
    let inputLength = Object.values(searchData).filter((el) => el !== " " && el !== "" && el !== undefined && el !== null);
    if (inputLength > 1) {
        for (const [k, v] of Object.entries(searchData)) {
            if (v === "") continue;
            let searchedKeywords = [];
            searchedKeywords.push(v);
            const searchId = `${source}__${uniqueKey}__${v}`;
            searchedNodes.push({
                label: `${v}`,
                id: searchId,
                color: { border: "gray", font: "black", background: "#fff" },
                borderWidth: 2,
                searchType: searchType,
                type: k,
                source: [source],
                isSubQueryNode: true,
            });
            searchedLinks.push({ to: centerNode.id, from: searchId, title: source });
        }
    }
    
    searchedNodes.push(centerNode);
    queryData.nodes = searchedNodes;
    queryData.edges = searchedLinks;
    return queryData
};
export const mergeAllNodeWithCentralnode = (allnodes,centralnodes) =>{
    [...allnodes.nodes, ...centralnodes.nodes, ...allnodes.dups].forEach((n) => {
        if (nodeKeyTypeMap[n.type] !== undefined) {
            n.type = nodeKeyTypeMap[n.type];
            n.searchType = nodeKeyTypeMap[n.type];
        }
    });
   return {
        nodes: [...allnodes.nodes, ...centralnodes.nodes, ...allnodes.dups],
        links: [...allnodes.links, ...centralnodes.edges],
    }
}