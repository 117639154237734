import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faPowerOff,
    faUserSecret,
    faDotCircle,
    faChevronCircleDown,
    faGlobe,
    faTextWidth,
    faCircleNotch,
    faSortAlphaDown,
    faSortNumericDown,
    faGlobeAmericas,
    faCheck,
    faCheckSquare,
    faUserCircle,
    faPlus,
    faCaretRight,
    faCaretUp,
    faCaretDown,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faEdit,
    faSave,
    faTrash,
    faChartLine,
    faSearchPlus,
    faClone,
    faDesktop,
    faClipboard,
    faCog,
    faExpand,
    faUserCog,
    faListAlt,
    faUser,
    faUsers,
    faKeyboard,
    faThList,
    faAngleLeft,
    faAngleDown,
    faAngleUp,
    faBars,
    faAngleRight,
    faExternalLinkAlt,
    faLink,
    faSearch,
    faBold,
    faItalic,
    faUnderline,
    faStrikethrough,
    faSuperscript,
    faSubscript,
    faPaintBrush,
    faTint,
    faMapMarkerAlt,
    faCalendarAlt,
    faImage,
    faNewspaper,
    faBirthdayCake,
    faEnvelope,
    faPhoneAlt,
    faBuilding,
    faGraduationCap,
    faVenusMars,
    faLanguage,
    faExclamationCircle,
    faExclamationTriangle,
    faAddressBook,
    faIcons,
    faTimes,
    faTachometerAlt,
    faCircle,
    faPlusCircle,
    faMinusCircle,
    faSitemap,
    faProjectDiagram,
    faWindowRestore,
    faDownload,
    faSync,
    faTable,
    faClock,
    faMinus,
    faEye,
    faSpellCheck,
    faCopy,
    faEllipsisV,
    faUserShield,
    faUpload,
    faChartBar,
    faFilter,
    faEyeSlash,
    faInfoCircle,
    faUserFriends,
    faTv,
    faArrowRight,
    faInfo,
    faGripVertical,
    faChevronCircleUp,
    faFolderOpen,
    faFolderPlus,
    faFolder,
    faEnvelopeOpen,
    faHome,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faUsersCog,
    faDatabase,
    faSort,
    faPen,
    faArrowUp,
    faArrowDown,
    faCaretLeft,
    faAsterisk,
    faUndo,
    faTools,
    faHistory,
} from "@fortawesome/free-solid-svg-icons";



import {
    faFileLines,
    faCircleCheck,
    faPenToSquare,
    faEnvelope as farEnvelope,
    faClock as farClock,
} from '@fortawesome/free-regular-svg-icons'



import {
    faInputText,
    faXmark,
    faBallotCheck,
    faPlus as farPlus,
} from '@fortawesome/pro-regular-svg-icons'



// import { faFilterSlash, faFilters } from "@fortawesome/pro-solid-svg-icons";
import { faSkype, faLinkedin, faGithub, faGoogle, faTwitter, faGitlab, faFlickr, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";



library.add(
    faBallotCheck,
    faAsterisk,
    faCaretLeft,
    faArrowUp,
    faArrowDown,
    faPen,
    faSort,
    faDatabase,
    faEnvelopeOpen,
    faFolder,
    faFolderPlus,
    faFolderOpen,
    faGripVertical,
    faInfo,
    faTv,
    faArrowRight,
    faUserFriends,
    faFilter,
    faInputText,
    faFileLines,
    faCircleCheck,
    faPenToSquare,
    faChevronCircleUp,
    // faFilterSlash,
    // faFilters,
    faEyeSlash,
    faTable,
    faPowerOff,
    faUserSecret,
    faDotCircle,
    faChevronCircleDown,
    faSync,
    faGlobe,
    faTextWidth,
    faCircleNotch,
    faSortAlphaDown,
    faSortNumericDown,
    faGlobeAmericas,
    faCheck,
    faCheckSquare,
    faUserCircle,
    faPlus,
    farPlus,
    faPlusCircle,
    faMinusCircle,
    faCaretRight,
    faCaretLeft,
    faCaretUp,
    faCaretDown,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faEdit,
    faSave,
    faTrash,
    faChartLine,
    faSearchPlus,
    faClone,
    faDesktop,
    faClipboard,
    faCog,
    faExpand,
    faUserCog,
    faListAlt,
    faUser,
    faUsers,
    faUserShield,
    faKeyboard,
    faThList,
    faAngleLeft,
    faAngleDown,
    faAngleUp,
    faBars,
    faAngleRight,
    faExternalLinkAlt,
    faLink,
    faSearch,
    faBold,
    faItalic,
    faUnderline,
    faStrikethrough,
    faSuperscript,
    faSubscript,
    faPaintBrush,
    faTint,
    faMapMarkerAlt,
    faCalendarAlt,
    faImage,
    faNewspaper,
    faBirthdayCake,
    faEnvelope,
    farEnvelope,
    faPhoneAlt,
    faBuilding,
    faGraduationCap,
    faVenusMars,
    faLanguage,
    faExclamationCircle,
    faExclamationTriangle,
    faAddressBook,
    faIcons,
    faTimes,
    faTachometerAlt,
    faCircle,
    faDownload,
    faSkype,
    faLinkedin,
    faGithub,
    faGoogle,
    faTwitter,
    faGitlab,
    faSitemap,
    faWindowRestore,
    faFlickr,
    faInstagram,
    faSitemap,
    faProjectDiagram,
    faClock,
    farClock,
    faMinus,
    faXmark,
    faEye,
    faSpellCheck,
    faCopy,
    faEllipsisV,
    faUpload,
    faChartBar,
    faInfoCircle,
    faFilter,
    faHome,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faUsersCog,
    faUndo,
    faTools,
    faHistory,
);


Vue.component("font-awesome-icon", FontAwesomeIcon);