<template>
  <div class="max-w-sm">
    <p class="mb-4 print-full overflow-auto scroll-bar pb-2 border-0 border-b rounded-md modalView consentContent" v-html="getPlaceholder"></p>
    <div class="flex justify-center mb-1 print--none" @click="showExpandModal">
        <svg data-v-eb36e366="" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" class="eye-icon"><path data-v-eb36e366="" d="M10.5003 2.5C14.9937 2.5 18.732 5.73313 19.5158 10C18.732 14.2668 14.9937 17.5 10.5003 17.5C6.00681 17.5 2.2685 14.2668 1.48474 10C2.2685 5.73313 6.00681 2.5 10.5003 2.5ZM10.5003 15.8333C14.03 15.8333 17.0503 13.3767 17.8148 10C17.0503 6.62336 14.03 4.16667 10.5003 4.16667C6.97054 4.16667 3.95022 6.62336 3.18569 10C3.95022 13.3767 6.97054 15.8333 10.5003 15.8333ZM10.5003 13.75C8.42919 13.75 6.75026 12.0711 6.75026 10C6.75026 7.92893 8.42919 6.25 10.5003 6.25C12.5713 6.25 14.2503 7.92893 14.2503 10C14.2503 12.0711 12.5713 13.75 10.5003 13.75ZM10.5003 12.0833C11.6509 12.0833 12.5836 11.1506 12.5836 10C12.5836 8.84942 11.6509 7.91667 10.5003 7.91667C9.34971 7.91667 8.41693 8.84942 8.41693 10C8.41693 11.1506 9.34971 12.0833 10.5003 12.0833Z" fill="#0D6AD5"></path></svg>
        <span data-v-eb36e366="" class="mx-1 firstbtn landscape-notVisible">View Consent</span>
    </div>
    <div class="flex gap-4 lg:gap-6">
      <div class="form-control  " v-for="opt in getOptions" :key="opt.id">
        <label class="label cursor-pointer" >
          <input
            type="radio"
            :name="context.name"
            class="flex-shrink-0 h-6 mr-2 cursor-pointer w-6 disabled:text-primary text-primary customform"
            :value="opt"
            v-model="getValue"
            :disabled="isDisabled"
          />
          <!-- :readonly="true" -->
          <span class="label-text "  v-text="opt.option_label" />
        </label>
      </div>
    </div>
    <ModalContent
           :name="this.context.attributes.placeholder " 
            ref="modal"
            title="Consent"
            close
            width="30%"
            height="50%"
            class="modalContent"
            :showFooter="false"
            :isConsentModal="true"
        >
            <template #content>
            <div clss="modal-content w-full">
                <div
                    class="w-full consent-content"
                    v-html="getPlaceholder"
                ></div>
            </div>
            </template>
        </ModalContent>
  </div>
</template>

<script>
import ModalContent from "../../../../shared/modal-content"
// import { isJson } from "@shared/components/form-builder/utils";
export default {
  components:{
    ModalContent
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
    data: () => {
      return {
        options: [
          {
            option_label: "Yes",
            option_value: "agree",
          },
          {
            option_label: "No",
            option_value: "disagree",
          },
        ],
      };
    },
  computed: {
    getPlaceholder() {
      return this.context.attributes.placeholder 
    },
    getValue: {
      get() {
        let value;
        if (this.context.model) {
          value = JSON.parse(this.context.model);
          return this.options.find(el => el.option_value === value?.value) 
        } else value = {};
        return value;
        // return IsJon(this.context.model) ? JSON.parse(this.context.model) : {};
      },
      set(val) {
        let value = {
          value: val?.option_value,
          date: Date.now(),
        };
        this.context.model = JSON.stringify(value);
      },
    },
    getOptions() {
      return this.options;
    },
    isDisabled() {
      return this.context?.attributes?.disabled ?? false
    }
  },

  methods: {
    setValue({ target }) {
      this.getValue = target.value;
    },
    showExpandModal() {
            console.log('showExpandModal');
            this.$refs.modal.showModal(this.context.attributes.placeholder);
        },
  },

  mounted() {
    console.log('this.context :>> ', this.context);
  },
};
</script>

<style>
.modalView{
  max-height: 128px;
  padding: 6px 11px;
  border: 1px solid grey;
}
.firstbtn{
  color: #0d6ad5;
  cursor: pointer;
}
.eye-icon{
  cursor: pointer;
}

@media print{
  .print-full{
    max-height: initial;
  }
}
</style>