export const LightRayRoutes = [
    {
        name: "light-ray",
        path: "light-ray",
        component: () => import(/* webpackChunkName:"light-ray"  */ "./index.vue"),
        meta:{
            showLightRay:true
        },
        children: [
            {
                name: "add-multientities",
                path: "add-multientities",
                component: () => import(/* webpackChunkName:"results-details" */ "./pages/add-multientities"),
                meta: {
                    title: "Add Company or Person", 
                    showLightRay:true
                }
            },
            {
                name: "add-person",
                path: "add-person",
                component: () => import(/* webpackChunkName:"results-details" */ "./pages/add-multientities/AddPerson"),
                meta: {
                    title: "Add Person", 
                    showLightRay:true
                }
            },
            {
                name: "select-features",
                path: "select-features",
                component: () => import(/* webpackChunkName:"results-details" */ "./pages/add-multientities/SelectFeatures"),
                meta: {
                    title: "select-features", 
                    showLightRay:true
                }
            },
            
            {
                name: "entity-details",
                path: "results/:tool",
                component: () => import(/* webpackChunkName:"results-details" */ "./pages/entity-details"),
                meta: {
                    title: "Entity Details", 
                    showLightRay:true
                }
            },
            {
                name: "search-company-records",
                path: "search-records/:type",
                component: () => import(/* webpackChunkName:"light-ray"  */"./pages/search-records"),
                meta: {
                    title: "Entity Details", 
                    showLightRay:true
                }
            },
            { 
                name: "select-entities",
                path: "select-entities/:type",
                component: () => import(/* webpackChunkName:"light-ray"  */"./pages/select-entities"),
                meta: {
                    title: "Entity Details", 
                    showLightRay:true
                }
            },
            { 
                name: "domain-records",
                path: "domain-records/:type",
                component: () => import(/* webpackChunkName:"light-ray"  */"./pages/company-domain"),
                meta: {
                    title: "Domain Deatils", 
                    showLightRay:true
                }
            },
            { 
                name: "company-records",
                path: "company-records/:type",
                component: () => import(/* webpackChunkName:"light-ray"  */"./pages/company-records"),
                meta: {
                    title: "Company Details", 
                    showLightRay:true
                }
            }
        ],
    },
];
