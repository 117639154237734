var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'formulate-input-element',
    {
      [`formulate-input-element--${_vm.context.class}`]: _vm.context.class,
    },
  ]},[_c('div',{staticClass:"editor field th-full tw-relative",class:{
      'editor--disabled': _vm.disabled,
      'editor--invalid field--invalid': _vm.invalid,
    },style:(_vm.styles)},[_c('div',{staticClass:"qeditor-wrapper"},[_c('quill-editor',{ref:"myQuillEditor",style:({ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }),attrs:{"disabled":_vm.disabled || this.$route.params.action == 'view',"id":_vm.editorId,"options":_vm.editorConfig},on:{"blur":function($event){return _vm.onEditorBlur($event)},"focus":function($event){return _vm.onEditorFocus($event)},"ready":function($event){return _vm.onEditorReady($event)}},model:{value:(_vm.context.model),callback:function ($$v) {_vm.$set(_vm.context, "model", $$v)},expression:"context.model"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }