import axios from "@/axios";
const { orderBy } = require("lodash")


export const CaseStore = {

    state: {
        caseData: null,
        caseStatusList: [],
    },

    mutations: {
        SET_CASE_DETAILS(state, caseData ) {
            state.caseData = caseData;
        },
        SET_CASE_STATUS_LISTS(state, caselist){
            state.caseStatusList = orderBy(caselist, 'status_order', 'asc')
        }
    },

    getters: {
        getCaseData: state => state.caseData,
        getNeoCaseId: state => state.caseData.neo_case_id || "QAT-10",
        getCaseStatusList: state => state.caseStatusList,
    },

    actions: {
        fetchCaseData: async ({ commit }, payload) => {
            if(payload){
                let case_id = payload
                const url = `/case/${case_id}`;
                try {
                    let { data } = await axios.get(url);
                    sessionStorage.setItem("client_id", data?.data?.client_id)
                    commit('SET_CASE_DETAILS', data.data)
                } catch (error) {
                    console.log("error>>>", error)

                    // Vue.$toast.warning("case not found!");
                }
            }
        },
        fetchCaseStatusLists: async({commit}, payload) => {
            const url = `/workflow/${payload.case_id}/status`;
            try {
                let { data } = await axios.get(url);
                commit('SET_CASE_STATUS_LISTS', data.data)
            } catch (error) {
                console.log("error>>>", error)
                // Vue.$toast.warning("case not found!");
            }
        }
    }

};