function createStorageStateHelper(localStorageStateKey) {
    if (typeof localStorageStateKey !== "string" || localStorageStateKey.length === 0) {
        throw new Error(`Invalid key provided: ${localStorageStateKey}`);
    }

    return {
        getState() {
            const storedStateStringify = localStorage.getItem(localStorageStateKey);
            if (storedStateStringify == null) {
                return null;
            }

            return JSON.parse(storedStateStringify);
        },
        setState(state) {
            const stringifiedState = JSON.stringify(state);
            localStorage.setItem(localStorageStateKey, stringifiedState);
        },
        unionState(partialState) {
            const storedState = this.getState();
            if (storedState == null) {
                this.setState(partialState);
            }

            this.setState({...storedState, ...partialState });
        },
    };
}

export default createStorageStateHelper;