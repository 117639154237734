<template>
    <div class="border py-2 px-4 rounded-lg border-box-stroke flex flex-wrap gap-3 formulate-pills" :data-type="context.type">
        <div v-for="option in value" :key="option.value" class="bg-gray-200 pl-2 pr-1 py-0.5 flex gap-3 items-center rounded-full">
            <div v-if="option.title">{{ option.title }}</div>
            <div v-if="context.attributes.elementLabel" :title="option[context.attributes.elementTooltip]">{{ option[context.attributes.elementLabel] }}</div>
            <div v-else>{{ option }}</div>
            <div class="rounded-full border-2 border-solid border-gray-700 font-semibold h-5 cursor-pointer w-5 flex items-center justify-center" @click="remove(option)">
                <font-awesome-icon icon="times" class="text-xs" />
            </div>
        </div>
        <input type="pills" ref="input" class="flex-1" :placeholder="context && context.attributes.placeholder" v-model.trim="inputValue" @keydown.8="removePillOnBack" @keydown.enter="addPill" @keydown.32="addPill" @blur="addPill" :disabled="isDisabled" />
    </div>
</template>

<script>
export default {
    name: "pills",
    props: {
        context: {
            type: Object,
            required: true,
            elementLabel: String,
            elementTooltip: String,
        },
    },
    data() {
        return {
            inputValue: "",
        };
    },
    computed: {
        value: {
            get() {
                return this.context.model || [];
            },
            set(val) {
                // console.log('val :>> ', val);
                this.context.model = val;
            },
        },
        isDisabled() {
            return this.context?.attributes?.disabled ?? false;
        },
    },
    mounted() {},
    methods: {
        setValue(val) {
            this.value = val || [];
        },
        addPill() {
            if (this.context.attributes.elementLabel) {
                // Split email IDs using regex pattern
                const pattern = /\s*,\s*/;
                const emailList = this.inputValue.split(pattern);

                // Extract email IDs and titles using regex patterns
                const emailRegex = /<([^>]+)>/;
                const titleRegex = /([^<]+)/;

                let values = [...this.value];
                emailList.forEach((email) => {
                    const emailMatch = email.match(emailRegex);
                    const titleMatch = email.match(titleRegex);
                    console.log('emailMatch :>> ', emailMatch);
                    console.log('titleMatch :>> ', titleMatch);
                    let emailObj = null;
                    if (emailMatch) {
                        const emailId = emailMatch[1];
                        emailObj = this.getEmailObj(emailId, titleMatch);
                    } else if (titleMatch) {
                        const emailId = titleMatch[1];
                        emailObj = this.getEmailObj(emailId, null);
                    }
                    if (emailObj) {
                        values.push(emailObj);
                    }
                });

                this.setValue(values);
            } else {
                if (this.inputValue.length > 0 && !this.value.includes(this.inputValue) && this.inputValue !== "") {
                    const validEmailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                    if (validEmailRegex.test(this.inputValue)) {
                        let values = [...this.value];
                        values.push(this.inputValue);
                        this.setValue(values);
                        this.inputValue = "";
                    }
                }
            }

            // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            // if (this.context.attributes.elementLabel) {
            //     if (this.inputValue.length > 0 && !this.value.map((el) => el[this.context.attributes.elementLabel]).includes(this.inputValue) && this.inputValue !== "") {
            //         if (emailRegex.test(this.inputValue)) {
            //             let values = [...this.value];
            //             values.push({
            //                 [this.context.attributes.elementLabel]: this.inputValue,
            //             });
            //             this.setValue(values);
            //             this.inputValue = "";
            //         }
            //     }
            // } else {
            // if (this.inputValue.length > 0 && !this.value.includes(this.inputValue) && this.inputValue !== "") {
            //     if (emailRegex.test(this.inputValue)) {
            //         let values = [...this.value];
            //         values.push(this.inputValue);
            //         this.setValue(values);
            //         this.inputValue = "";
            //     }
            // }
            // }
        },
        remove(pill) {
            this.inputValue = "";
            let values;
            if (this.context.attributes.elementLabel) values = this.value.filter((el) => el[this.context.attributes.elementLabel] != pill[this.context.attributes.elementLabel]);
            else values = this.value.filter((el) => el != pill);
            this.setValue(values);
        },
        removePillOnBack() {
            if (!this.inputValue) {
                this.value.length > 0 && this.value.pop();
            }
        },
        getEmailObj(emailId, titleMatch = null) {
            const validEmailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            let emailObj = null;
            if (validEmailRegex.test(emailId)) {
                const isEmailAlreadyExist = this.value.map((el) => el[this.context.attributes.elementLabel].toLowerCase()).includes(emailId.toLowerCase());
                if (!isEmailAlreadyExist) {
                    emailObj = { [this.context.attributes.elementLabel]: emailId };
                    if (titleMatch) {
                        const title = titleMatch[1];
                        emailObj["title"] = title;
                    }
                    this.inputValue = "";
                } else {
                    this.$toast.error(`${emailId} Already exist!`);
                }
            } 
            return emailObj;
        },
    },
};
</script>
