<template>
    <div
        class="content-box bg-white rounded-lg w-full"
        :class="{
            'overflow-y-auto': overflowYAuto,
            'overflow-x-auto': overflowXAuto,
            'overflow-auto': overflowAuto,
            'p-5': !showHeader,
        }"
    >
        <template v-if="showHeader">
            <div
                class="content-box__header h-9 rounded-t-lg"
                :class="headerClasses"
            >
                <slot name="header" />
            </div>
            
            <div
                class="content-box__content p-5"
                :class="contentClasses"
            >
                <slot />
            </div>
        </template>
        <slot v-else />

        <slot name="footer"></slot>
    </div>
</template>

<script>
export default {
    props: {
        overflowYAuto: {
            type: Boolean,
            default: false,
        },
        overflowXAuto: {
            type: Boolean,
            default: false,
        },
        overflowAuto: {
            type: Boolean,
            default: false,
        },
        showHeader: {
            type: Boolean,
            default: false,
        },
        contentClasses: {
            type: [String, Array, Object],
            default: '',
        },
        headerClasses: {
            type: [String, Array, Object],
            default: '',
        },
    },
}
</script>

<style lang="scss" scoped>
</style>