
export const SET_FIELDS_TYPES = "fields-admin/SET_FIELDS_TYPES";
export const FETCH_FIELDS = "fields-admin/FETCH_FIELDS";
import axios from "@/axios";
import { cloneDeep, isEqual } from "lodash";
export const FieldsAminStore = {
	state: {
		fields_types: [],
		fieldData: null,
		originFieldData: null,
		isDirty: false
	},
	mutations: {
		[SET_FIELDS_TYPES](state, { fields_types }) {
			state.fields_types = fields_types;
		},
		SET_FIELD_DATA(state, data) {
            state.fieldData = cloneDeep(data);
            state.originFieldData = cloneDeep(data);
            state.isDirty = false;
        },
        SET_FIELD_DATA_DIRTY(state, payload) {
            // eslint-disable-next-line no-unused-vars
            let { entity_types, ...restPayload } = payload;
            // eslint-disable-next-line no-unused-vars
            const { entity_types: origin_entity_types, ...restoriginData } = state.originFieldData;

            const collectionNames = [
                'entity_types'
            ];

            const entity_typesModel = {
                id: 'string',
                maximum_entity_count: 'number',
                minimum_entity_count: 'number',
                name: 'string'
            }
            
            const isCollectionsEqual = collectionNames.every(collectionName => {
                let isEqual = true;

                const source = state.originFieldData[collectionName];
                const target = payload[collectionName]

                if (source.length != target.length) {
                    isEqual = false;
                } else {
                    for (let i = 0; i < source.length; i++) {
                        const sourceItem = source[i]
                        const targetItem = target.find(x => x.id == sourceItem.id)
                        if (!targetItem) {
                            isEqual = false;
                            break;
                        } else {
                            for (const key in entity_typesModel) {
                                if (sourceItem[key] != targetItem[key]) {
                                    isEqual = false;
                                    break;
                                }
                            }
                        }
                    }
                }
                return isEqual;
            })
            
            state.isDirty = !isCollectionsEqual || !isEqual(cloneDeep(restPayload), cloneDeep(restoriginData))
        },
	},
	getters: {
		fieldsType: state => {
			return state.fields_types;
		}
	},
	actions: {
		[FETCH_FIELDS]: async ({ commit }) => {
			commit(SET_FIELDS_TYPES, { fields_types: [] });
			let result = await axios.get('/fields/types')
			let new_result = result.data.field_types
			let fieldIcons = {
				"Input": "/icons/svg/input-text.svg",
				"Large text": '/icons/svg/input-text.svg',
				"Number": "/icons/svg/input-numeric.svg",
				"Radio": "/icons/svg/circle-dot.svg",
				"Radio group": "/icons/svg/circle-dot.svg",
				"Single select": "/icons/svg/circle-dot.svg",
				"Multi select": "/icons/svg/circle-dot.svg",
				"Date range": "/icons/svg/calendar-week.svg",
				"File": "/icons/svg/folder-arrow-up.svg",
				"Checkbox": "/icons/svg/square-check.svg",
				"Checkbox group": "/icons/svg/square-check.svg",
				"custom_field": "/icons/svg/input-text.svg",
			}
			let fields_list = new_result.map(el => {
				return { ...el, icon: fieldIcons[el.name] || "/icons/svg/circle-dot.svg" }
			})

			// let fieldList = {

			// }
			commit(SET_FIELDS_TYPES, { fields_types: fields_list });

		},
        setFieldData: ({ commit }, payload) => {
             commit('SET_FIELD_DATA', payload);
        },
		
        handleFieldDataChange: ({ commit }, payload) => {
			console.log({payload})
             commit('SET_FIELD_DATA_DIRTY', payload);
        }
	}
};

