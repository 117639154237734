<template>
  <div class="card-base shadow-md relative rounded-2xl bg-card-bg flex gap-1 flex-col overflow-hidden">
    <div
      v-if="$slots.title"
      class="card-base__title flex items-center justify-between font-bold text-base-content bg-primary-300 gap-3 px-4 lg:px-5 py-3 lg:py-4 rounded-t-2xl w-full"
    >
      <p class="card-base__title-label">
        <slot name="title" />
      </p>
      <div class="card-base__title-append">
        <slot name="title-append" />
      </div>
    </div>
    <div
      v-if="$slots.default"
      class="card-base__content p-4 lg:p-5 flex flex-col w-full overflow-auto scroll-bar"
    >
      <slot />
    </div>
    <div
      v-if="$slots.controls"
      class="card-base__controls p-4 lg:p-5 flex items-center justify-end w-full"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "UISection",
}
</script>

<style lang="scss" scoped>
</style>