import { fetchRefreshClientToken, fetchRefreshAccessToken } from "../services"
import store from "@/store"
import VueCookies from "vue-cookies"
// import { mapGetters } from "vuex";

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

// const getState = mapGetters(["getIsTenant", "getIsClient"])


const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const refreshTokeninterceptor = (axiosInstance) => async (error) => {
  const _axios = axiosInstance;
  const originalRequest = error.config;
  const { status } = error.response;
  if (!originalRequest._retry) {
    if (status === 401 || status === 403) {
      if (isRefreshing) {
        try {
              const token = await new Promise(function (resolve, reject) {
                  failedQueue.push({ resolve, reject });
              });
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return _axios.request(originalRequest);
          } catch (err) {
              return await Promise.reject(err);
          }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const access_token = VueCookies.get("token");
      const refresh_token = VueCookies.get("refresh_token");
      const isClient=VueCookies.get("isClient")=== 'true' ? true : false
      const retryOriginalRequest = new Promise((resolve, reject) => {
        if (isClient) {
          fetchRefreshClientToken(access_token)
            .then((token) => {
              VueCookies.set("token", token);
              _axios.defaults.headers.common["Authorization"] = "Bearer " + token;
              originalRequest.headers["Authorization"] = "Bearer " + token;
              processQueue(null, token);
              resolve(_axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
              store.dispatch("logoutUser");
            })
            .then(() => {
              isRefreshing = false;
            });
        } else {
          fetchRefreshAccessToken(access_token, refresh_token)
            .then((token) => {
              VueCookies.remove("token");
              VueCookies.set("token", token);
              _axios.defaults.headers.common["Authorization"] = "Bearer " + token;
              originalRequest.headers["Authorization"] = "Bearer " + token;
              processQueue(null, token);
              resolve(_axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
              store.dispatch("logoutUser");
            })
            .then(() => {
              isRefreshing = false;
            });
        }
      });
      return retryOriginalRequest;
    }
  }

  return Promise.reject(error);
};

export default refreshTokeninterceptor;